/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider.state('move-money.western-union', {
    url: '/western-union',
    templateUrl: 'apps/western-union/templates/acp-western-union.ng.html',
    resolve: {
      pageTitle: /* @ngInject */ function (module, $injector) {
        return $injector.invoke(
          nsUtilProvider.getPageTitle('apps/western-union')
        );
      },
      module: /* @ngInject */ function ($ocLazyLoad) {
        return import(
          /* webpackChunkName: "acp.apps.western-union" */ 'apps/western-union'
        ).then($ocLazyLoad.loadModule);
      },
      $isPartnerChannel: /*@ngInject */ function (nsPermissions) {
        return nsPermissions.requestPermission('isPartnerChannel');
      }
    },
    data: {
      permissions: {
        except: [
          'isCIPConditionalDocumentsNotUploaded',
          'isCIPConditionalDocumentsUploaded'
        ],
        redirectTo: {
          isCIPConditionalDocumentsNotUploaded: 'dashboard',
          isCIPConditionalDocumentsUploaded: 'dashboard'
        }
      }
    }
  });
}

export default states;
